<div class="tab-item skills">
    <div style="margin-bottom: 10px">
        <span class="chip language">言語</span>
        <span class="chip framework">フレームワーク</span>
        <span class="chip ui-component">UIコンポーネント</span>
        <span class="chip infra">インフラ</span>
        <span class="chip">その他</span>
    </div>
    <div class="field" *ngFor="let info of infos">
        <label class="title">{{ info.name }}</label>
        <div>
            <div *ngFor="let skill of info.skills" class="chip {{ skill.category }}">
                {{ skill.name }}
            </div>
        </div>
    </div>
</div>
