<div class="about-page">
    <h1>てらしーとは</h1>
    <img class="icon" src="/assets/img/my-icon.jpeg" />

    <div class="sns-links">
        <div #twitter class="sns-item twitter">
            <a target="_blank" href="https://twitter.com/teracy164"> <img src="assets/img/x-logo-black.png"
                    alt="X" /></a>
        </div>
        <div class="sns-item qiita">
            <a target="_blank" href="https://qiita.com/teracy164"> <img src="assets/img/qiita-qiita-jobs-favicon.png"
                    alt="qiita" /></a>
        </div>
        <div class="sns-item github">
            <a target="_blank" href="https://github.com/teracy164"><img src="assets/img/GitHub-Mark-32px.png"
                    alt="github" /></a>
        </div>
        <div class="sns-item lapras">
            <a target="_blank" href="https://lapras.com/public/F0PHINH" title="LAPRAS"><img
                    src="assets/img/lapras-logo.png" alt="lapras" /></a>
        </div>
    </div>
    ※連絡を取りたい場合はX(旧Twitter)のDMにてお願いします※

    <mat-tab-group [selectedIndex]="selectedIndex" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.label">
            <app-careers *ngIf="i === IDX_CAREERS"></app-careers>
            <app-skills *ngIf="i === IDX_SKILLS"></app-skills>
            <app-qualifications *ngIf="i === IDX_QUALIFICATIONS"></app-qualifications>
        </mat-tab>
    </mat-tab-group>
</div>