<div class="tab-item qualifications">
    <div class="wrapper">
        <div fxLayout="row wrap">
            <ng-container *ngFor="let qualification of qualifications">
                <div fxFlex="100%" fxFlex.gt-xs="20%" [ngClass.xs]="'mobile date'">{{ qualification.date | date: 'yyyy年MM月' }}</div>
                <div fxFlex="100%" fxFlex.gt-xs="80%" [ngClass.xs]="'mobile name'">{{ qualification.name }}</div>
            </ng-container>
        </div>
    </div>
</div>
